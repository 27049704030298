const axios = require('axios');

const readCookie = require('../readCookie');
const logger = require('../createLogger')('invokeS3Download.js');

module.exports = async function(objectKey) {
    logger.info('invoking s3_download');

    logger.info(`objectKey: ${objectKey}`);

    if (!objectKey) {
        return null;
    }

    const idToken = readCookie('id_token');
    const authorizationValue = `Bearer ${idToken}`;

    const response = await axios({
        method: 'GET',
        url: `https://${process.env.VUE_APP_HOST_PUBLIC_API}/api/v1/s3/userfiles?file=${objectKey}`,
        headers: {
            'Content-type': 'application/json',
            Authorization: authorizationValue,
        },
    });

    const { signedUrl } = response.data;

    if (!signedUrl) {
        logger.error(`error when trying to get signed file: ${objectKey}`);
        return;
    }

    logger.info('sucessfully received signed url');
    return signedUrl;
};
