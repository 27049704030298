const { OrbitControls } = require('three/examples/jsm/controls/OrbitControls');

const log = (message) => console.log('constructOrbitControls:', message);

const event = {
    that: null,

    change() {
        event.that.renderScene();
    },

    start() {
        log('start');
    },

    end() {
        log('end');
    },
};

module.exports = function({ that, options }) {
    log('construct');

    event.that = that;

    const controls = new OrbitControls(that.camera, that.renderer.domElement);
    controls.target = options.target;
    controls.enablePan = false;
    controls.minDistance = 1;

    controls.addEventListener('change', event.change);
    controls.addEventListener('start', event.start);
    controls.addEventListener('end', event.end);

    return controls;
};
