const log = (message) => console.log('treeifyJson:', message);

function treeifyObject([key, value]) {
    const children = [];

    for (const property in value) {
        children.push(treeifyElement([property, value[property]]));
    }

    const tree = key
        ? {
              text: key,
              children,
          }
        : {
              text: 'Object',
              children,
          };

    return tree;
}

function treeifyArray([key, value]) {
    const children = [];

    for (const v of value) {
        children.push(treeifyElement([null, v]));
    }

    const tree = key
        ? {
              text: key,
              children,
          }
        : {
              text: 'Array',
              children,
          };

    return tree;
}

function treeifyBoolean([key, value]) {
    return key ? { text: `${key}: ${value}` } : { text: value };
}

function treeifyNumber([key, value]) {
    return key ? { text: `${key}: ${value}` } : { text: value };
}

function treeifyString([key, value]) {
    return key ? { text: `${key}: ${value}` } : { text: value };
}

function treeifyElement([key, value]) {
    if (Array.isArray(value)) {
        return treeifyArray([key, value]);
    } else if (typeof value === 'object') {
        return treeifyObject([key, value]);
    } else if (typeof value === 'boolean') {
        return treeifyBoolean([key, value]);
    } else if (typeof value === 'number') {
        return treeifyNumber([key, value]);
    } else if (typeof value === 'string') {
        return treeifyString([key, value]);
    }

    throw new Error(`element ${key} has unkown type ${typeof value}`);
}

module.exports = function(json) {
    log('treeifying json');

    const treeifiedJson = treeifyElement([null, json]);

    return treeifiedJson;
};
