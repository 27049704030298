const { GLTFLoader } = require('three/examples/jsm/loaders/GLTFLoader.js');

const log = (message) => console.log('loadMesh:', message);

module.exports = function({ that, filePath }) {
    log(filePath);
    const loader = new GLTFLoader();

    return new Promise((resolve, reject) => {
        // https://threejsfundamentals.org/threejs/lessons/threejs-lights.html
        loader.load(
            filePath,
            (result) => {
                log('mesh loaded');
                const mesh = result.scene;

                //const material = new Three.MeshStandardMaterial({ color: 0xeeeeee, roughness: 0.2, metalness: 0.8 });
                //const material = new Three.MeshPhongMaterial({
                //    color: 0xAAAABB,    // red (can also use a CSS color string here)
                //    flatShading: true,
                //});

                //mesh.traverse((child) => {
                //    if (child.isMesh) {
                //        child.material = material;
                //        child.material.side = Three.DoubleSide;
                //    }
                //});

                resolve(mesh);
            },
            (event) => {
                log(`mesh loading: ${event.loaded}/${event.total}`);

                if (!that.isActive) {
                    log('render is no longer active, abort loading');
                    event.currentTarget.abort();
                }
            },
            (error) => {
                log('mesh loading error');
                log(error);
                reject(error);
            },
        );
    });
};
