const {
    DeviceOrientationControls,
} = require('three/examples/jsm/controls/DeviceOrientationControls');

const log = (message) =>
    console.log('constructDeviceOrientationControls:', message);

const event = {
    that: null,
    controls: null,

    change() {},

    animateScene() {
        log('animateScene');

        if (!event.isActive) {
            return;
        }

        event.controls.update();

        event.that.renderScene();

        requestAnimationFrame(event.animateScene);
    },
};

module.exports = function(that) {
    log('construct');

    event.that = that;

    const controls = new DeviceOrientationControls(that.camera);

    controls.addEventListener('change', event.change);

    // override dispose to make sure animateScene is exited once the controls change
    const superDispose = controls.dispose;
    controls.dispose = () => {
        event.isActive = false;
        superDispose();
    };

    event.controls = controls;

    if (!event.isActive) {
        event.isActive = true;
        event.animateScene();
    }

    return controls;
};
