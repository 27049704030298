const log = (message) => console.log('expandMeshGeometryRefs:', message);

function expandGroup(group, geometries) {
    const expandedGroup = {
        ...group,
        children: group.children.map((c) => expandMeshElement(c, geometries)),
    };

    return expandedGroup;
}

function expandMesh(mesh, geometries) {
    const { geometry: meshGeometryUuid } = mesh;

    const meshGeometry = geometries.find(
        (geometry) => geometry.uuid === meshGeometryUuid,
    );

    const expandedMesh = {
        ...mesh,
        geometry: meshGeometry,
    };

    return expandedMesh;
}

function expandMeshElement(meshElement, geometries) {
    const { type } = meshElement;

    if (type === 'Group') {
        return expandGroup(meshElement, geometries);
    } else if (type === 'Mesh') {
        return expandMesh(meshElement, geometries);
    }

    throw new Error(`mesh element ${meshElement.uuid} has unkown type ${type}`);
}

module.exports = function(reducedMesh) {
    log('expanding reduced mesh geometries');

    const expandedMesh = {
        metadata: reducedMesh.metadata,
        object: expandMeshElement(reducedMesh.object, reducedMesh.geometries),
    };

    return expandedMesh;
};
