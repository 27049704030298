const {
    TrackballControls,
} = require('three/examples/jsm/controls/TrackballControls');

const log = (message) => console.log('constructTrackballControls:', message);

const event = {
    that: null,
    controls: null,
    isActive: false,

    lastFrameStart: 0,

    change() {
        event.lastFrameStart = Date.now();
    },

    start() {
        log('start');

        event.lastFrameStart = Date.now();

        if (!event.isActive) {
            event.isActive = true;
            event.animateScene();
        }
    },

    end() {
        log('end');
    },

    animateScene() {
        if (!event.isActive) {
            return;
        }

        if (Date.now() - event.lastFrameStart > 1000) {
            event.isActive = false;
            return;
        }

        log('animateScene');

        event.controls.update();

        event.that.renderScene();

        requestAnimationFrame(event.animateScene);
    },
};

module.exports = function({ that, options }) {
    log('construct');

    event.that = that;

    const controls = new TrackballControls(
        that.camera,
        that.renderer.domElement,
    );

    controls.addEventListener('change', event.change);
    controls.addEventListener('start', event.start);
    controls.addEventListener('end', event.end);

    // undocumented, but seems to work
    controls.target = options.target;

    // override dispose to make sure animateScene is exited once the controls change
    const superDispose = controls.dispose;
    controls.dispose = () => {
        event.isActive = false;
        superDispose();
    };

    event.controls = controls;

    return controls;
};
