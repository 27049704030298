const { DragControls } = require('three/examples/jsm/controls/DragControls');

const log = (message) => console.log('constructDragControls:', message);

const event = {
    that: null,
    semanticsTree: null,

    selectedObject: null,
    originalMaterial: null,

    drag() {
        event.that.renderScene();
    },

    uff() {
        waitCheckRun();
    },

    dragstart(e) {
        log(`dragstart uuid ${e.object.uuid}`);
        log(e.object);

        addDraggedObject(e.object);
        log(
            `draggedObjects so far: ${event.that.draggedObjects.map(
                (o) => o.object.id,
            )}`,
        );

        // unselect previous selection
        event.semanticsTree.selected().unselect();

        // select new elements
        const selection = event.semanticsTree.find(
            new RegExp(e.object.uuid.toLowerCase()),
        );

        let node = selection[0];

        // expand all nodes to the dragged element
        while (node) {
            log(`expanding node ${node.key}`);
            node.expand(true);
            node = node.parent;
        }
        selection[0].select(true);

        // wait for the element to update properly, then scroll it into view
        waitCheckRun({
            timeout: 1000,
            checkInterval: 50,
            checkFunction: () => selection[0].vm,
            runFunction: () =>
                selection[0].vm.$el.scrollIntoView({
                    behavior: 'auto',
                    block: 'center',
                    inline: 'center',
                }),
        });

        event.that.selectObject(e.object);
    },

    dragend() {
        log('dragend');
    },

    hoveron() {
        log('hoveron');
    },

    hoveroff() {
        log('hoveroff');
    },
};

function addDraggedObject(object) {
    log('addDraggedObject');
    const { draggedObjects } = event.that;

    if (
        draggedObjects.some(
            (draggedObject) => draggedObject.object.id === object.id,
        )
    ) {
        return;
    }

    draggedObjects.push({
        object,
        position: {
            x: object.position.x,
            y: object.position.y,
            z: object.position.z,
        },
    });
}

function waitCheckRun({ timeout, checkInterval, checkFunction, runFunction }) {
    log(`waitCheckRun with timeout ${timeout}`);
    if (timeout <= 0) {
        return;
    }

    if (checkFunction()) {
        runFunction();
        log('waitAndCheck runFunction executed');
        return;
    }

    setTimeout(() => {
        waitCheckRun({
            timeout: timeout - checkInterval,
            checkInterval,
            checkFunction,
            runFunction,
        });
    }, checkInterval);
}

module.exports = function(that) {
    log('construct');

    event.that = that;
    event.semanticsTree = that.$refs.semanticsTree;

    const controls = new DragControls(
        that.loadedMeshes,
        that.camera,
        that.renderer.domElement,
    );

    controls.addEventListener('drag', event.drag);
    controls.addEventListener('dragstart', event.dragstart);
    controls.addEventListener('dragend', event.dragend);
    controls.addEventListener('hoveron', event.hoveron);
    controls.addEventListener('hoveroff', event.hoveroff);

    return controls;
};
