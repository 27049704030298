<template>
    <div id="render" class="header_space">
        <div v-if="isSceneLoading" class="loading_image">
            <img src="../assets/loading.gif" />
        </div>

        <splitpanes
            v-show="!isSceneLoading"
            id="main_splitpanes"
            :horizontal="isSplitHorizontal"
            class="default-theme"
        >
            <pane>
                <div id="container" />
            </pane>
            <pane class="tree_pane" size="25">
                <h2 class="main_font">
                    Scene Tree
                </h2>
                <tree ref="semanticsTree" class="main_font" />
            </pane>
        </splitpanes>
    </div>
</template>

<script>
import * as Three from 'three';

import invokeS3Download from '../util/api/invokeS3Download';

import constructDeviceOrientationControls from './render/constructDeviceOrientationControls';
import constructDragControls from './render/constructDragControls';
import constructOrbitControls from './render/constructOrbitControls';
import constructTrackballControls from './render/constructTrackballControls';
import detectTouchScreen from './render/detectTouchScreen';
import loadMesh from './render/loadMesh';

import initTreeComponent from './render/initTreeComponent';
//https://hilongjw.github.io/vue-progressbar/index.html
const log = (message) => console.log('Render:', message);

function setRenderControls(controlsEnum, options = {}) {
    log(`setRenderControls ${controlsEnum}`);

    if (this.controls) {
        this.controls.dispose();
    }

    if (controlsEnum === 'orbit_controls') {
        this.controls = constructOrbitControls({
            that: this,
            options,
        });
    } else if (controlsEnum === 'device_orientation_controls') {
        this.controls = constructDeviceOrientationControls(this);
    } else if (controlsEnum === 'drag_controls') {
        this.controls = constructDragControls(this);
    } else if (controlsEnum === 'trackball_controls') {
        this.controls = constructTrackballControls({
            that: this,
            options,
        });
    } else {
        log(`error: controls ${controlsEnum} not recognized, controls not set`);
    }

    if (this.controls && this.controls.update) {
        this.controls.update();
    }
}

function setRenderControlsOptions() {
    log('setRenderControlsOptions');

    const boundingSphere = new Three.Sphere();

    new Three.Box3()
        .setFromObject(this.scene)
        .getBoundingSphere(boundingSphere);

    log(boundingSphere);

    this.renderControlsOptions = {
        target: boundingSphere.center,
        cameraDistance: boundingSphere.radius * 10,
    };
}

async function initScene(hasTouchScreen) {
    log('initScene');

    // note that the final canvas size is determined by 'resizeCanvas'
    const canvasWidth = 1;
    const canvasHeight = 1;

    const container = document.getElementById('container');

    this.camera = new Three.PerspectiveCamera(
        70,
        canvasWidth / canvasHeight,
        0.01,
        100,
    );

    if (this.scene) {
        this.scene.clear();
    }
    this.scene = new Three.Scene();

    this.scene.background = new Three.Color(0xf0f0f0);

    const hlSkyColor = 0xdddddd;
    const hlGroundColor = 0x231130;
    const hlIntensity = 0.25;
    const hl = new Three.HemisphereLight(
        hlSkyColor,
        hlGroundColor,
        hlIntensity,
    );
    this.scene.add(hl);

    const dlColor = 0xffffff;
    const dlIntensity = 1;
    const dl = new Three.DirectionalLight(dlColor, dlIntensity);
    dl.position.set(20, 7, 10);
    dl.target.position.set(0, 0, 0);
    this.scene.add(dl);
    this.scene.add(dl.target);

    try {
        const mesh = this.signedFileUrl
            ? await loadMesh({ that: this, filePath: this.signedFileUrl })
            : await loadMesh({
                  that: this,
                  filePath: 'https://hypeifc-cint-public.s3.eu-central-1.amazonaws.com/210220_EFH_klein.glb',
              });

        initTreeComponent({ that: this, mesh });
        this.loadedMeshes.push(mesh);
        this.scene.add(mesh);

        //log('loading dummy mesh');
        //await new Promise(resolve => {
        //    setTimeout(() => resolve(), 1000);
        //});

        //const geometry = new Three.BoxGeometry(1.2, 1.2, 1.2);
        //const material = new Three.MeshNormalMaterial();
        //const mesh = new Three.Mesh(geometry, material);
        //this.loadedMeshes.push(mesh);
        //this.scene.add(mesh);

        //const geometryIco = new Three.IcosahedronGeometry(2, 3);
        //geometryIco.translate(-3, 2, 1);
        //const materialIco = new Three.MeshNormalMaterial();
        //const meshIco = new Three.Mesh(geometryIco, materialIco);
        //this.loadedMeshes.push(meshIco);
        //this.scene.add(meshIco);
    } catch (e) {
        console.error('initScene was not able to load the mesh', e);
        return;
    }

    this.setRenderControlsOptions();

    this.renderer = new Three.WebGLRenderer({ antialias: true });
    this.renderer.outputEncoding = Three.sRGBEncoding;
    this.renderer.setSize(canvasWidth, canvasHeight);

    container.appendChild(this.renderer.domElement);

    if (hasTouchScreen) {
        this.header.items = [
            {
                key: 'trackball_controls',
                icon: '3d_rotation',
            },
            {
                key: 'drag_controls',
                icon: 'drag_indicator',
            },
            {
                key: 'device_orientation_controls',
                icon: 'screen_rotation',
            },
        ];
    } else {
        this.header.items = [
            {
                key: 'orbit_controls',
                icon: '360',
            },
            {
                key: 'drag_controls',
                icon: 'drag_indicator',
            },
            {
                key: 'trackball_controls',
                icon: '3d_rotation',
            },
        ];
    }

    this.camera.position.x =
        this.renderControlsOptions.target.x +
        this.renderControlsOptions.cameraDistance;
    this.camera.position.x = this.renderControlsOptions.target.y;
    this.camera.position.z =
        this.renderControlsOptions.target.z +
        this.renderControlsOptions.cameraDistance / 3;
    this.camera.far = this.renderControlsOptions.cameraDistance * 2;
    this.camera.lookAt(
        this.renderControlsOptions.target.x,
        this.renderControlsOptions.target.y,
        this.renderControlsOptions.target.z,
    );
    this.camera.updateProjectionMatrix();

    this.resizeCanvas();

    this.setRenderControls(
        this.header.items[0].key,
        this.renderControlsOptions,
    );

    window.addEventListener('resize', this.resizeCanvas);
}

function initSplitpanes() {
    this.repositionSplitpane();
    window.addEventListener('resize', this.repositionSplitpane);
}

function repositionSplitpane() {
    log('repositionSplitpane');

    if (window.innerWidth / window.innerHeight < 1) {
        this.isSplitHorizontal = true;
        return;
    }

    this.isSplitHorizontal = false;
}

function resizeCanvas() {
    const actualSize = new Three.Vector2();
    this.renderer.getSize(actualSize);

    const headerHeight = window
        .getComputedStyle(document.documentElement)
        .getPropertyValue('--header_height');

    const targetSize = new Three.Vector2(
        window.innerWidth - 50,
        window.innerHeight - parseInt(headerHeight, 10) - 20,
    );

    if (actualSize.equals(targetSize)) {
        return;
    }

    log('resizing canvas');
    this.renderer.setSize(targetSize.x, targetSize.y);
    this.camera.aspect = targetSize.x / targetSize.y;
    this.camera.updateProjectionMatrix();

    if (this.controls && this.controls.update) {
        this.controls.update();
    }

    this.renderScene();
}

function selectObject(object) {
    log('selectObject');

    if (this.selectedObject) {
        this.unselectObject();
    }

    this.selectedObject = object;
    this.originalMaterial = object.material.clone();
    const emissiveMaterial = object.material.clone();
    emissiveMaterial.emissive.set(0x555555);
    object.material = emissiveMaterial;

    this.renderScene();
}

function unselectObject() {
    log('unselectObject');

    if (!this.selectedObject) {
        log('there is nothing to unselect');
        return;
    }

    this.selectedObject.material = this.originalMaterial;
    this.selectedObject = null;

    this.renderScene();
}

function renderScene() {
    this.renderer.render(this.scene, this.camera);
}

function listenToSelectItem(index) {
    log(`listenToSelectItem ${index}`);
    this.setRenderControls(
        this.header.items[index].key,
        this.renderControlsOptions,
    );
}

function listenToResetModel() {
    log('listenToResetModel');

    for (const draggedObject of this.draggedObjects) {
        draggedObject.object.position.x = draggedObject.position.x;
        draggedObject.object.position.y = draggedObject.position.y;
        draggedObject.object.position.z = draggedObject.position.z;
    }

    this.draggedObjects = [];

    this.renderScene();
}

function registerListeners() {
    this.$root.$on('render_select_item', this.listenToSelectItem);
    this.$root.$on('render_reset_model', this.listenToResetModel);
}

function data() {
    return {
        isActive: true,
        header: {
            title: 'Render',
            type: 'render',
            selectedIndex: 0,
            items: [
                {
                    key: 'loading',
                    icon: 'mood',
                },
            ],
        },
        isSceneLoading: true,
        isSplitHorizontal: false,
        canvas: null,
        signedFileUrl: null,

        camera: null,
        scene: null,
        renderer: null,
        controls: null,
        loadedMeshes: [],
        renderControlsOptions: null,

        selectedObject: null,
        originalMaterial: null,

        draggedObjects: [],
    };
}

function setHeader() {
    this.$root.$emit('set_header_title', this.header);
}

export default {
    props: {
        objectKey: {
            type: String,
            default: null,
        },
    },
    data,

    async mounted() {
        log('render');

        this.isActive = true;
        this.setHeader();

        this.initSplitpanes();

        this.registerListeners();

        const hasTouchScreen = detectTouchScreen();

        this.signedFileUrl = await invokeS3Download(this.objectKey);

        await this.initScene(hasTouchScreen);

        this.isSceneLoading = false;

        // TODO: for some reason track ball controls are not usable without this...
        // see issue #45
        setTimeout(() => {
            log('delayed setRenderControls');
            this.setRenderControls(
                this.header.items[0].key,
                this.renderControlsOptions,
            );
        }, 100);
    },

    destroyed() {
        log('destroyed render');
        this.isActive = false;
    },

    methods: {
        initScene,
        initSplitpanes,
        listenToResetModel,
        listenToSelectItem,
        registerListeners,
        renderScene,
        repositionSplitpane,
        resizeCanvas,
        selectObject,
        setHeader,
        setRenderControls,
        setRenderControlsOptions,
        unselectObject,
    },
};
</script>

<style scoped>
#render {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: var(--main_color_bg_lighter);
}

.tree_pane {
    overflow-y: scroll;
    background-color: var(--main_color_bg_light) !important;
}
</style>

<!--
    repeated code in Compliance.vue and Render.vue
    for some reason this only has effect when it is not 'scoped'
-->
<style>
.tree-arrow.has-child::after {
    border-color: grey !important;
    border-width: 2px !important;
}

.tree-node.selected > .tree-content {
    background-color: var(--main_color_fg_dark) !important;
}

.tree-node.selected > .tree-content > .tree-anchor {
    color: white;
}
</style>
