const log = (message) => console.log('reduceMesh:', message);

function reduceBufferGeometry(bufferGeometry) {
    const reducedBufferGeometry = {
        uuid: bufferGeometry.uuid.toLowerCase(),
        type: bufferGeometry.type,
        data: {
            boundingSphere: bufferGeometry.data.boundingSphere,
        },
    };

    return reducedBufferGeometry;
}

function reduceGroup(group) {
    const reducedGroup = {
        uuid: group.uuid.toLowerCase(),
        type: group.type,
        layers: group.layers,
        children: group.children.map(reduceMeshElement),
    };

    return reducedGroup;
}

function reduceMesh(mesh) {
    const reducedMesh = {
        uuid: mesh.uuid.toLowerCase(),
        type: mesh.type,
        name: mesh.name,
        layers: mesh.layers,
        geometry: mesh.geometry.toLowerCase(),
    };

    return reducedMesh;
}

function reduceMeshElement(meshElement) {
    const { type } = meshElement;

    if (type === 'BufferGeometry') {
        return reduceBufferGeometry(meshElement);
    } else if (type === 'Group') {
        return reduceGroup(meshElement);
    } else if (type === 'Mesh') {
        return reduceMesh(meshElement);
    }

    throw new Error(`mesh element ${meshElement.uuid} has unkown type ${type}`);
}

module.exports = function(mesh) {
    log('reducing mesh');

    const reducedMesh = {
        metadata: mesh.metadata,
        geometries: mesh.geometries.map(reduceMeshElement),
        object: reduceMeshElement(mesh.object),
    };

    return reducedMesh;
};
