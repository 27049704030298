const reduceMesh = require('./reduceMesh');
const expandMeshGeometryRefs = require('./expandMeshGeometryRefs');
const treeifyJson = require('./treeifyJson');

const log = (message) => console.log('initTreeComponent:', message);

const event = {
    that: null,
    mesh: null,

    clicked(e) {
        log('clicked');
        selectEntry(e);
    },

    doubleClicked() {
        log('doubleClicked');
    },

    selected(e) {
        log('selected');
        selectEntry(e);
    },

    unselected() {
        log('unselected');
        event.that.unselectObject();
    },
};

function selectEntry(e) {
    log('selectEntry');

    const { text } = e.data;
    const uuid = extractUuid(text);

    if (!uuid) {
        log('doubleClicked, but entry is no uuid');
        return;
    }

    log(uuid);
    const object = event.mesh.getObjectByProperty('uuid', uuid.toUpperCase());

    if (!object) {
        log('doubleClicked, but object with uuid not found');
        return;
    }

    if (!object.material) {
        log('doubleClicked, but found object has no material');
        return;
    }

    event.that.selectObject(object);
}

function extractUuid(string) {
    const extract = string.match(
        /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/i,
    );

    return extract && extract[0];
}

module.exports = ({ that, mesh }) => {
    log('init');

    event.that = that;
    event.mesh = mesh;

    // the stringify and parse is needed to map all the attributes
    const fullMesh = JSON.parse(JSON.stringify(mesh));

    const reducedMesh = reduceMesh(fullMesh);
    const expandedMesh = expandMeshGeometryRefs(reducedMesh);
    const treeifiedMesh = treeifyJson(expandedMesh);

    const treeComponent = that.$refs.semanticsTree;
    treeComponent.tree.setModel(treeifiedMesh);
    treeComponent.$on('node:clicked', event.clicked);
    treeComponent.$on('node:dblclick', event.doubleClicked);
    treeComponent.$on('node:selected', event.selected);
    treeComponent.$on('node:unselected', event.unselected);
};
